.terms{
	
	&__content-wrap{
		max-width: 116rem;
		margin: 0 auto;
		padding-top: 14.3rem;
		display: flex;
		padding-bottom: 10rem;
		@include tablet{
			max-width: 100%;
			padding: 4rem 2.4rem;
		}
	}
	&__nav{
		width: 20rem;
		margin-#{$end-direction}: 3.9rem;
		@include tablet{
			display: none;
		}

	}
	&__segment{
		color: #2A3D38;
		width: 68.1rem;
		@include tablet{
			width: 100%;
		}
		
		.section{
			padding-top: 3.7rem;
			margin-bottom: 6.1rem;
			border-top: 1px solid #2A3D38;
		}
		h2{
			margin: 0 0 1.4rem;
			font-size: 6rem*$font_incra;	
			font-weight: $bold-font;	
			line-height: 7.6rem*$font_incra;
			@include tablet{
				font-size: 3rem*$font_incra;
				 line-height: 1.5;
			}
		}
		h3{
			margin: 0 0 1.2rem;
			font-size: 2.4rem*$font_incra;	
			font-weight: $bold-font;	
			line-height: 2.3rem*$font_incra;
			@include tablet{
				font-size: 2rem*$font_incra;
			}
		}
		p, ol, ul{
			font-size: 1.6rem*$font_incra;	
			line-height: 1.4;
			margin: 0 0 2.7rem;
			@include tablet{
				font-size: 1.2rem*$font_incra;
			}

		}
	}
}