@font-face {
    font-family: 'RAGSans';
    src: url('fonts/RAG-Sans-65-Regular.woff2') format('woff2'),
         url('fonts/RAG-Sans-65-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

// @font-face {
//     font-family: 'RAGSans';
//     src: url('fonts/RAG-Sans-64-Black.eot') format('opentype'),
//          url('fonts/RAG-Sans-64-Black.woff2') format('woff2'),
//          url('fonts/RAG-Sans-64-Black.woff') format('woff');
//     font-weight: 900;
//     font-style: normal;
// }

@font-face {
    font-family: 'RAGSans';
    src: url('fonts/RAG-Sans-65-Bold.woff2') format('woff2'),
         url('fonts/RAG-Sans-65-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

// @font-face {
//     font-family: 'RAGSans';
//     src: url('fonts/RAG-Sans-64-ExtraBold.eot') format('opentype'),
//          url('fonts/RAG-Sans-64-ExtraBold.woff2') format('woff2'),
//          url('fonts/RAG-Sans-64-ExtraBold.woff') format('woff');
//     font-weight: 800;
//     font-style: normal;
// }
// @font-face {
//     font-family: 'RAGSans';
//     src: url('fonts/RAG-Sans-64-ExtraLight.eot') format('opentype'),
//          url('fonts/RAG-Sans-64-ExtraLight.woff2') format('woff2'),
//          url('fonts/RAG-Sans-64-ExtraLight.woff') format('woff');
//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'RAGSans';
//     src: url('fonts/RAG-Sans-64-Light.eot') format('opentype'),
//          url('fonts/RAG-Sans-64-Light.woff2') format('woff2'),
//          url('fonts/RAG-Sans-64-Light.woff') format('woff');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'RAGSans';
//     src: url('fonts/RAG-Sans-64-Medium.eot') format('opentype'),
//          url('fonts/RAG-Sans-64-Medium.woff2') format('woff2'),
//          url('fonts/RAG-Sans-64-Medium.woff') format('woff');
//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'RAGSans';
//     src: url('fonts/RAG-Sans-64-SemiBold.eot') format('opentype'),
//          url('fonts/RAG-Sans-64-SemiBold.woff2') format('woff2'),
//          url('fonts/RAG-Sans-64-SemiBold.woff') format('woff');
//     font-weight: 500;
//     font-style: normal;
// }


// $extralight-font: 100;
// $light-font: 200;
$normal-font: 400;
// $medium-font: 400;
// $semibold-font: 500;
$bold-font: 700;
// $black-font: 800;
// $extrabold-font: 900;

html {
	font-size: 10px;
	@include desktop{
  		font-size: .69vw;
  	}
	@include tablet{
		font-size: 2.66vw;
	}
}
body {
  color: $black;
	font-family: 'RAGSans';
	font-weight: $normal-font;
  letter-spacing: 0px;

}
