.error-page{
	&__main-content{
		min-height: calc(100vh - var(--footer-height) - var(--header-height));
	}
	&__title{
		color: #2A3D38;	
		font-size: 76rem*$font_incra;	
		font-weight: 900;	
		line-height: 74.5rem*$font_incra;	
		text-align: center;
		margin: 0;
		@include tablet{
			margin-top: 3rem;
			font-size: 20rem*$font_incra;	
			font-weight: 900;	
			line-height: 1;	
		}
	}
}