
.font{
	&--index{
		padding: 0 0 11.9rem;
		margin-bottom: 11.9rem;
		border-bottom: 1px solid #979797;
		display: flex;
		justify-content: space-between;
		@include tablet{
			padding: 0 0 3.4rem;
			margin-bottom: 2.5rem;
			display: block;
			text-align: center;
		}
		&:last-child{
			border-bottom: 0px;
			margin-bottom: 14.1rem;
		}
		&__display{
			margin: 0;
			flex-grow: 2;
			@include tablet{
				margin-bottom: 3.2rem;
			}
			a{
				display: block;
				color: $black;
				text-decoration: none;
				&:hover, &:active{
					color: $pink;
				}
			}
		}
		&__info{
			width: 18.7rem;
			font-size: 2rem*$font_incra;
			line-height: 2.7rem*$font_incra;
			@include tablet{
				width: 100%;
				font-size: 1.4rem*$font_incra;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}
		&__text{
			margin: 0;
			color: $black;
			white-space: pre-wrap;
			padding: 0 0 2.7rem;
			font-weight: $normal-font;
			@include tablet{
				margin: 0;
				padding-bottom: 0;
			}
		}
	}
	&--single{
		@import "font-sections/speciman";
		@import "font-sections/testers";
		@import "font-sections/features";
		@import "font-sections/info";
		
		&__main-content{
			padding-top: 0;
		}
		.banner{
			min-height: 33rem;
			box-sizing: border-box;
			background-color: $black;
			color: $white;
			padding: 13.8rem 0 3.4rem;
			&__controls-wrap{
				display: flex;
				align-items: center;
				justify-content: center;
			}
			&__controls{
				margin: 0 5.7rem;
				@include tablet{
					margin: 0 1.5rem;
				}
			}
		}
		&__title{
			margin: 0 0 4rem;
			span{
				display: block;
			}
		}
		&__section-title{
			margin: 0;
			font-size: 2.5rem*$font_incra;
			font-weight: bold;
			line-height: 3.1rem*$font_incra;
			text-align: center;
			@include tablet{
				font-size: 1.6rem*$font_incra;	
			}
		}
		
		.navigation{
			font-size: 2rem*$font_incra;
			line-height: 2.5rem*$font_incra;
			z-index: 999;
			top: 0 !important;
			&.on-scroll{
				
		        background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,1) 60%,rgba(0,0,0,0) 61%,rgba(0,0,0,0) 100%);
		        z-index: 9;
		    }
			@include tablet{
				font-size: 1.2rem*$font_incra;
			}
			&__inner{
				margin-#{$start-direction}: -9.65rem;
			}
			&__content-wrap{
				max-width: 100%;
			}
			&__wrap{
				height: 8rem;
				display: flex;
				align-items: center;
				background-color: $light-gray;
				justify-content: space-between;
				padding-top: 0;
				padding-#{$start-direction}:9.65rem;
				padding-#{$end-direction}: 0;
				padding-bottom: 0;
				@include tablet{
					height: 7.5rem;
					padding-#{$start-direction}: 2.15rem;
				}
			}
			&__inner{
				@include tablet{
					display: none;
				}
			}
			.dropdown--font{
				height: 100%;
				&__btn{
					width: 21.4rem;
					height: 100%;
					font-weight: 800;
					@include tablet{
						width: 20rem;

					}
				}
				
			}
		}
		.in-use{
			padding-bottom: 5.2rem;
			&__title{
				margin-bottom: 6.4rem;
				@include tablet{
					margin-bottom: 2.5rem;
				}
			}
		}
		.next-font{
			background-color: $black;
			color: $white;
			padding: 5.7rem 0 10rem;
			text-align: center;
			&__title{
				margin: 0 0 2.2rem;
				text-align: center;
				font-size: 2.5rem*$font_incra;	
				font-weight: bold;	
				line-height: 3.1rem*$font_incra;	
				@include tablet{
					margin: 0 0 .5rem;
					font-size: 1.6rem*$font_incra;
				}
			}
			
				a{
					font-size: inherit;
					line-height: inherit;
					letter-spacing: inherit;
					color: inherit;
					text-decoration: none;
					font-weight: inherit;
				}
			
		}
		.buying-options{
			top:-8rem;
			height: 0px;
			overflow: hidden;
			color: $white;
			position: relative;
			background-color: $pink;
			@include tablet{
				top: 0px;
				position: fixed;
				width: 100%;
			}
			&__content-wrap{
				max-width: 115.8rem;
				margin: 0 auto;
				padding-top: 4.3rem;
				padding-bottom: 5.9rem;
				@include tablet{
					max-width: 100%;
					height: 100%;
					padding: 2.5rem 2.2rem 0;
					box-sizing: border-box;


				}
			}
			&__title{
				margin: 0;
				font-size: 2.5rem*$font_incra;
				line-height: 2.5rem*$font_incra;
				margin-bottom: 2.7rem;
				font-weight: normal;
				@include tablet{
					font-size: 1.2rem*$font_incra;
				}
			}
			&__btn{
				svg{
					#Home-Mobile-Menu{
						stroke: $black;
					}
				}
			}
			.license{
				display: flex;
				height: 9.3rem;
				align-items: center;
				justify-content: space-between;
				border-top: 1px solid;
				
				&.active{
					background-color: #FF92BD;
				}
				@include tablet{
					display: block;
					height: auto;
					padding: 2.35rem 1.55rem;
				}
				&__options{
					@include tablet{
						height: 0;
						overflow: hidden;
					}
				}
				&__inner-options{
					padding: 2.15rem 3rem 2.45rem;
					.dropdown:first-child{
						margin-bottom: 2.5rem;
					}
				}
				&__prices{
					width: 20%;
					text-align: $end-direction;
					margin-#{$start-direction}: 15.35rem;
					@include tablet{
						display: none;
					}
				}
				&__full-price{
					opacity: 1;
					margin-#{$end-direction}: 1rem;
					color: #2A3D38;
					font-weight: normal;
					position: relative;
					display: inline-block;
					&::before{
						content: '';
						width: 100%;
						height: 1px;
						background-color: #2A3D38;
						top: calc(50% - .5px);
						position: absolute;
						right:0;
					}
					// text-decoration: line-through;
					&.hidden{
						opacity: 0;
					}

				}
				&__price{
					padding-#{$end-direction}: 1rem;
					text-align: $end-direction;
					@include tablet{
						visibility: hidden;
						position: absolute;
					}
				}
				&:last-child{
					border-bottom: 1px solid;
				}
			}
		}

	}
}