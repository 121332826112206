.features{
	&__content-wrap{
		max-width: 116rem;
		padding: 9rem 0 10.8rem;
		@include tablet{
			max-width: 100%;
			padding: 7.3rem 2.2rem 6rem;
		}
	}
	&__title{
		margin-bottom: 7.8rem;
		@include tablet{
			margin-bottom: 1.7rem;
		}
	}
	.image-wrap{
		@include tablet{
			width: 100% !important;
			margin-bottom: .5rem;
		}
		&__title{
			margin: 0 0 1.5rem;
			font-weight: normal;
			color: #6A7975;
			font-size: 1.6rem*$font_incra;;
			text-align: center;
			line-height: 2.5rem*$font_incra;;
			@include tablet{
				text-align: $start-direction;
				margin: 0;
				font-size: 1rem*$font_incra;;
			}
			
		}
		img{
			width: 100%;
			height: auto;
		}
	}
	&__multi-image{
		display: flex;
		justify-content: space-between;
		@include tablet{
			flex-direction: column;
		}
	}
	.triple-image{
		&__image-wrap{
			width: 36rem;
		}
	}
}