.icon {
	display: block;
    svg{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
	&--logo{
		height: 3.8rem;
    	width: 8.5rem;
        @include tablet{
            height: 2.7rem;
            width: 5.6rem;
        }
    	
	}
    &--arrow-scroll{
        width: 3.8rem;
        height: 6.4rem;
        @include tablet{
            height: 4.2rem;
            width: 2.5rem;
        }
    }
    &--menu-logo{
        height: 1.4rem;
        width: 3rem;

    }
    &--testimonial{
        display: none;
        width: 9rem;
        margin: 0 auto;
        height: 9rem;
        background: url(images/testimonials.png) center / contain no-repeat;
        
      
    }
    &--cart-count{
        height: 2rem;
        width: 2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-style: normal;
        font-size: 1.6rem*$font_incra;;
        border-radius: 50%;
        background-color: $pink;
        margin-#{$start-direction}: .7rem;
        position: absolute;
        top: calc(-1rem + 50%);
        @include tablet{
            margin-#{$start-direction}: 0rem;
            #{$start-direction}: -3rem;
        }

    }
    &--logo-loader{
        height: 3.8rem;
        width: 8.5rem;
        margin: 45vh auto 0;
        animation-name: heartBeat;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        @include tablet{
            height: 2.7rem;
            width: 5.6rem;
        }

        svg{
            g{
                g{
                    fill: $black;
                }
            }
        }
    }
 
}