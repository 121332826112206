.about{
	&__main-content{
		padding-top: 0;
	}
	&__section{
		position: relative;
		background-color: $white;
		&--main{
			height: 100vh;
			background-size: cover;
			// margin-bottom: 30.5rem;
			background-position: center;

		}
		&--brands-fonts{
			color: $white;
			padding: 23.1rem 0 10.9rem;
			background-color: $dark-gray;
			@include tablet{
				padding: 7.6rem 0 9.4rem;
			}
		}
	}
	&__title-wrap{
		transform: translateY(44.9rem);
	}
	&__title{
		position: relative;
		margin: 0 0 53.8rem;
		color: $white;
		font-weight: $normal-font;
		font-size: 6rem*$font_incra;	
		line-height: 1;
		display: block;

		@include tablet{
			font-size: 2.32rem; //after 1.16 change	
			margin-bottom: 40rem;
			// margin: 3.9rem 0 .8rem;
		}
		
		// transform: translateY(44.9rem);

	}
	&__title-text{
		margin: 0;
		color: $pink;
		font-weight: $bold-font;
		font-size: 5rem*$font_incra;	
		line-height: 1.1;
		white-space: pre-line;

    	// transform: translateY(100vh);
    	@include tablet{
    		font-size: 2.2rem*$font_incra;	
    	}
	}
	&__content-wrap{
		max-width: 124rem;
		@include tablet{
			max-width: 100%;
			padding: 0 2.2rem;
		}
	}
	.main-about-section{
		&__bkg{
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: rgba(0,0,0,0.4);
		}
	}
	.brands-fonts{
		@include make-row();
		margin-bottom: 19.8rem;
		justify-content: space-between;
		@include tablet{
			margin-bottom: 12.2rem;
			flex-direction: column-reverse;
		}
		&:last-child{
			margin-bottom: 0px;
		}
		&:nth-child(odd){
			flex-direction: row-reverse;
			@include tablet{
				flex-direction: column-reverse;
			}
		}
		&__text-wrap{
			@include make-col(5.285);
			@include tablet{
				@include make-col(12);
			}
		}
		&__image-wrap{
			@include make-col(5.285);
			@include tablet{
				@include make-col(8);
				margin-bottom: 5.3rem;
			}
			img{
				width: 100%;
			}
		}
		&__title{
			width: 35.2rem;
			font-size: 4rem; //after 1.16	
			line-height: 5.2rem*$font_incra;
			margin: 0 0 1rem;
			font-weight: $bold-font;	
			@include tablet{
				width: 20.7rem;
				font-size: 2rem*$font_incra;	
				margin: 0 0 2rem;
				line-height: 2.5rem*$font_incra;
			}
		}
		&__text{
			margin: 0 0 2.9rem;
			font-size: 2rem*$font_incra;	
			line-height: 1.3;
			@include tablet{
				margin: 0 0 3.5rem;
				font-size: 1.4rem*$font_incra;	
				
			}
		}
	}
	.considerations{
		&:first-child{
			.considerations__content-wrap{
				padding-top: 14.2rem;
			}
		}
		&__content-wrap{
			@include make-row();
			margin: 0 auto;
			padding: 9rem 0 9.5rem;
			justify-content: space-between;
			
			@include tablet{
				padding: 4.3rem 2.2rem 3.8rem;
			}
		}
		&__title{
			margin: 0;
			@include make-col(3.5);
			font-size: 4rem*$font_incra;	
			font-weight: $bold-font;	
			line-height: 1;
			@include tablet{
				@include make-col(8);
				font-size: 2rem*$font_incra;
				margin: 0 0 4.4rem;
				line-height: 2.5rem*$font_incra;
				
			}
		}
		&__container{
			column-count: 2;
			column-gap: 12rem;
			@include make-col(8);
			@include tablet{
				@include make-col(10);
				column-count: 1;
			}

		}
		.consideration{
			break-inside: avoid;
			// &:last-child{
			// 	margin-top: 7.2rem;
			// 	@include tablet{
			// 		margin-top: 0;
			// 	}
			// }
			&__title{
				font-weight: $normal-font;
				margin: 0 0 1.5rem;
				font-size: 2rem*$font_incra;	
				line-height: 2.5rem*$font_incra;
				@include tablet{
					margin: 0 0 0.8rem;
					font-size: 1.4rem*$font_incra;	
					line-height: 1.8rem*$font_incra;
				}
			}
			&__text{
				color: $gray;
				font-size: 1.6rem*$font_incra;	
				line-height: 1.4;
				margin: 0 0 5.3rem;
				@include tablet{
					margin: 0 0 3.5rem;
					font-size: 1.2rem*$font_incra;	
				}
			}
		}
	}

}