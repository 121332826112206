.slick-lightbox{
	background: rgba(0,0,0,.9);

	.slick-slider{
		position: relative;
	}
	
	.slick-lightbox-slick-item-inner{
		position: relative;
		width: 100rem;
		&::after{
	    	content: '';
	    	height: 26.5rem;
			display: block;
			width: 100%;
			bottom: 0;
			position: absolute;
			mix-blend-mode: multiply;
			background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(42,61,56,0.29) 43.24%, #2A3D38 100%);


	    }
		img{
			width: 100%;
			max-width: 100%;
		    height: 68rem;
		    object-fit: cover;
		    position: relative;
		    @include tablet{
		    	width: 33rem;
		    	height: 33rem;
		    }
		    

		}
		span{
			z-index: 1;
			bottom: 3.3rem;
			position: absolute;
			color: $white;	
			font-size: 2rem*$font_incra;;	
			line-height: 3.1rem*$font_incra;;	
			text-align: center;
			@include tablet{
				position: relative;
				bottom: 0;
				margin-top: 0.7rem;
				display: block;
				font-size: 1rem*$font_incra;;
				line-height: 1rem*$font_incra;;
			 
			}
		}
	}
	.slick-arrow{
		height: 6rem;	
		width: 4.6rem;
		position: absolute;
		z-index: 1;
		font-size: 0px;
		border: 0px;
		cursor: pointer;
		-webkit-appearance: none;
		top: 40.1rem;
		background-color: transparent;
		&:focus{
			outline: none;
		}
	}
	.slick-arrow{
		top: calc(50% - 3.1rem);
		width: 4.8rem;
		height: 6.2rem;
	}
	.slick-next{
		#{$start-direction}: calc(50% - 50rem + 0.9rem);
		background: url(images/arrow-#{$start-direction}-white.svg) center / contain no-repeat;
	}
	.slick-prev{
		#{$end-direction}: calc(50% - 50rem + 0.9rem);
		#{$start-direction}: unset !important;
		background: url(images/arrow-#{$end-direction}-white.svg) center / contain no-repeat;
	}
	 .slick-lightbox-close{
	 	display: none;
	 }
}
.carousel{
	position: relative;
	direction: $direction;
	.slick-list{
		@include tablet{
			padding: 0 !important;
		}
	}
	.slick-arrow{
		height: 6rem;	
		width: 4.6rem;
		position: absolute;
		z-index: 1;
		font-size: 0px;
		border: 0px;
		cursor: pointer;
		-webkit-appearance: none;
		top: 40.1rem;
		background-color: transparent;
		&:focus{
			outline: none;
		}
	}
	.slick-next{
		left: 0;
		background: url(images/arrow-left.svg) center / contain no-repeat;
	}
	.slick-prev{
		right: 0;
		background: url(images/arrow-right.svg) center / contain no-repeat;
	}
	&--in-use{
		padding: 0;
		.slick-list{
			padding: 0 !important;
			&::before, &::after{
				content: '';
				width: 10rem;
				height: 36.3rem;
				position: absolute;
				top: 0;
				z-index: 1;
				background-color: rgba(0,0,0,0.53);
				@include tablet{
					display: none;
				}
			}
			&::before{
				left: 0;
			}
			&::after{
				right: 0;
			}
		}
		.slick-slide{
			width: 36.3rem;
			
			margin: 0 2rem;
			position: relative;
			@include tablet{
				width: 23.6rem;
				margin:  0 .5rem;
				
			}

			img{
				width: 100%;
				height: 36.3rem;
				object-fit: cover;
				@include tablet{
					height: 23.6rem;
				}
			}
			span{
				color: #2A3D38;	
				font-size: 1.4rem*$font_incra;;	
				letter-spacing: -0.007rem;	
				line-height: 4rem*$font_incra;;
				@include tablet{
					display: block;
					text-align: center;
					font-size: 1rem*$font_incra;;
					line-height: 1rem*$font_incra;;
					letter-spacing: -0.005rem;
					margin-top: 1rem;
				}
			}
		}
		.slick-arrow{
			width: 4.8rem;
			height: 6.2rem;
			top: 18.15rem;
		}
		.slick-next{
			left: 5.2rem;
			background: url(images/arrow-left-light.svg) center / contain no-repeat;
		}
		.slick-prev{
			right: 5.2rem;
			background: url(images/arrow-right-light.svg) center / contain no-repeat;
		}
		
	}
	&--homepage{
		position: absolute !important;;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		.slick-list, .slick-track{
			padding: 0 !important;
			width: 100%;
			height: 100%;
		}
		&__slide{
			width: 100%;
			height: 100%;
		}
	}
}
