@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes heartBeat { 
	0%{
    	transform: scale( .75 );
  	}
  	20%{
    	transform: scale( 1 );
  	}
  	40%{
    	transform: scale( .75 );
  	}
  	60%{
    	transform: scale( 1 );
    }
    80%{
    	transform: scale( .75 );
    }
    100%{
    	transform: scale( .75 );
    }
}