//font family page
.weight-wrap{
	text-align: center;	
	direction: ltr;	
}
.weight-range{
	margin: 0 auto;
	width: 16.2rem;
	height: 1px;
	position: relative;
	background-color: $pink;
	
	&__title{
		direction: $direction;
		display: block;
		text-align: center;
		margin-top: 1.5rem;
		font-size: 1.6rem*$font_incra;;	
		color: $pink;
		line-height: 2.5rem*$font_incra;;	
		@include tablet{
			margin-top: 1.6rem;
			font-size: 1.2rem*$font_incra;;	
			font-weight: 300;	
		}

	}
	&__indicator{
		height: 1px;
		width: 0px;
		left: 0;
		position: absolute;
		&::after{
			content: '';
			z-index: 10 !important;
			width: 2.3rem;
			height: 2.3rem;
			top: -1.15rem;
			cursor: pointer !important;
			position: absolute;
			border-radius: 50%;
			background-color: $black;
			border: 1px solid $pink;
			#{$start-direction}: -1.15rem;
		}
		@include tablet{
			&::after{
				width: 1.5rem;
				height: 1.5rem;
				top: -0.85rem;
				#{$start-direction}: -0.85rem;
			}
		}
		
	}
}

.font-controls{

	@include make-row();
	
	.weight-select{
		@include make-col(4);
		text-align: $start-direction;
		position: relative;
		&__dropdown{
			width: 13.2rem;
			position: absolute;
			height: 0px;
			overflow: hidden;
			background-color: $white;
			@include tablet{
				width: 10rem;
				z-index: 9999;

			}
		}
		&__dropdowm-menu{
			list-style: none;
			padding: 0 ;
			margin: 0px;

			border: 1px solid $light-gray;
		}
		&__list-item{
			transition: all .2s ease-in;
			padding: .5rem 1.35rem .35rem;
			&:hover{
				background-color: $light-gray;
			}
		}
	}
	.size-wrap{
		direction: ltr;
		text-align: center;
		@include make-col(4);
		&__title{
			direction: $direction;
			// color: $pink;
			display: block;
			text-align: center;
			margin-bottom: 1.9rem;
			font-size: 1.6rem*$font_incra;;	
			font-weight: $normal-font;	
			line-height: 2.5rem*$font_incra;;	
			@include tablet{
				display: none;
			}
		}
	}
	.size-range{
		margin: 0 auto;
		width: 20rem;
		height: 1px;
		position: relative;
		// background-color: $pink;
		@include tablet{
			width: 6.5rem;
		}
		&__indicator{
			height: 1px;
			width: 0px;
			left: 0;
			position: absolute;
			&::after{
				content: '';
				width: 2.3rem;
				height: 2.3rem;
				top: -1.15rem;
				cursor: pointer !important;
				position: absolute;
				border-radius: 50%;
				background-color: inherit;
				#{$start-direction}: -1.15rem;
			}
			@include tablet{
				&::after{
					width: 1.3rem;
					height: 1.3rem;
					top: -0.65rem;
					#{$start-direction}: -0.65rem;
				}
			}
		}
	}
	.align-select{
		&__btn{
			cursor: pointer;
			border: 0px;
			background-color: transparent;
			-webkit-appearance: none;
			svg{
				height: 1.7rem;
				width: auto;
			}
			&.active{
				svg{
					g{
						g{
							stroke: $pink;
						}
					}
				}
			}
		}
	}
	.color-select{
		@include make-col(4);
		text-align: $end-direction;
		&--font-page{
			position: relative;
			.btn--color-select{
				border: 1px solid #000000;
				&.active{
					border: 1px solid $pink;
				}
			}
		}
		
	}

}