button{
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    padding: 0;
    font-family: inherit;
}
.btn , a{
	color: $pink;
	cursor: pointer;
    font-size: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-weight: inherit;
	text-decoration: underline;
	-webkit-appearance: none;
	background-color: transparent;
    border: none;
    padding: 0;
    font-family: inherit;
    transition: all .2s ease-in;
    cursor: pointer;

    &:focus{
    	outline: none;
    	box-shadow: none;
    }
    &--animated{
        text-decoration: none;
        display: inline-block;
        line-height: normal;
        transition: all .25s ease-in;
        &::after{
            content: '';
            background-color: $pink;
            display: block;
            height: 1px;
            width: 0px;
            transition: all .25s ease-in;
        }
        &:hover{
            color: $pink;
            &::after{
                width: 100%;
            }
        }
    }
    &--home-title{
        text-decoration: none;
    	display: inline-block;
        &::after{
            content: '';
            background-color: $pink;
            display: block;
            height: .4rem;
            width: 0px;
            transition: all .25s ease-in;
        }
        &:hover{
            &::after{
                width: 100%;
            }
        }
    	// border-bottom: .4rem solid $pink;
        
    }
    &--footer-social{
    	width: auto;
        color: inherit;
        text-decoration: none;
    	margin-#{$end-direction}: 2.2rem;
    }
    &--contact-brands{
        text-decoration: none;
        font-size: 4rem*$font_incra;;
        font-weight: $normal-font;
        line-height: 1.2;
        text-align: center;
        display: block;
        position: relative;
        margin-bottom: 8.7rem;
        @include tablet{
            display: none;
        }
        // &::after{
        //     content: '';
        //     background-color: $pink;
        //     height: .4rem;
        //     width: 75.5rem;
        //     position: absolute;
        //     bottom: 0;
        //     left: calc(50% - 75.5rem/2);
        // }
    }
    &--footer-cta{
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid;
        line-height: .9;
        display: inline-block;

    }
    &--mail-footer{
        color: inherit;
        display: block;
        margin-top: 3rem;
       
    }
    &--phone-footer{
        color: inherit;
        text-decoration: none;
        
    }
    &--contact-footer{
        @include tablet{
            margin-top: 0;
            display: inline-block;
            width: 10.2rem;
            margin-#{$end-direction}: 1.2rem;
            font-weight: $normal-font;
        }
    }
    &--terms{
        // width: 31.8rem;
        color: inherit;
        text-decoration: none;
        margin-#{$end-direction}: 2rem;
    }
    &--access{
        color: inherit;
        text-decoration: none;
    }
    &--footer-big-social{
        color: inherit;
        // display: block;
        text-decoration: none;
        line-height: inherit;
        @include tablet{
            margin-#{$end-direction}: 1.9rem;
        }
    }
    &--font-credit{
       display: inline-block;
        @include tablet{
            display: inline-block;
            color: $pink;
            // text-decoration: none;
        }

    }
    &--credit{
        color: $white;
    }
    
    &--back-to-brands{
        text-decoration: none;
        margin: 1rem 0 0 ;
        color: $white;
        // display: block;
        font-size: 1.8rem*$font_incra;;
        text-align: center;
        line-height: 1.8rem*$font_incra;;  
        @include tablet{
            margin: 2.9rem 0;
            font-size: 1.6rem*$font_incra;; 
            text-align: center;   
            line-height: 1.8rem*$font_incra;;  
            font-weight: $bold-font;  

        }

    }
    &--brands-fonts{
        color: $white;
        text-decoration: none;
        font-size: 2.2rem*$font_incra;;    
        font-weight: $bold-font;   
        @include tablet{
            font-size: 1.4rem*$font_incra;;    
            line-height: 1.8rem*$font_incra;;
        }
    }
    &--color-select{
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 50%;
        margin: 0 0.9rem;
        box-sizing: content-box;
        @include tablet{
            width: 1.2rem;
            height: 1.2rem;
        }

    }
    &--color-switch{
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        
    }
    &--weight-dropdown{
        color: inherit;
        text-decoration: none;
        margin-bottom: .6rem;
        font-size: 1.6rem*$font_incra;;    
        font-weight: $normal-font;   
        line-height: 2.5rem*$font_incra;;
        @include tablet{
            font-size: 1rem*$font_incra;;    
            line-height: 2.5rem*$font_incra;;
        }
        .icon{
            width: 2rem;
            height: 2rem;
            margin: 0 .5rem;
            transform: rotate(180deg);
            display: inline-block;
            vertical-align: middle;
            transition: all .2s ease-in;
            @include tablet{
                width: 1.7rem;
                height: 1.7rem;
            }

        }
        &.open{
            .icon{
                transform: rotate(0deg);
            }
        }

    }
    &--weight-select{
        color: $gray;
        width: 100%;
        text-align: $start-direction;
        text-transform: capitalize;
        text-decoration: none;
        font-weight: $normal-font;
        font-size: 1.6rem*$font_incra;;    
        line-height: 2.5rem*$font_incra;;
        @include tablet{
            font-size: 1rem*$font_incra;; //TOHMCHECK
        }
    }
    &--navigation{

        color: $black;
        font-size: 2.5rem*$font_incra;;  
        padding-bottom: .65rem;  
        line-height: 2.9rem*$font_incra;;  
        text-decoration: none;
        border-bottom: .2rem solid transparent;
        @include tablet{
            font-size: 1.3rem*$font_incra;;
            line-height: 1.6rem*$font_incra;;
        }
        
    }
    &--arrow-up{
        position: absolute;
        #{$end-direction}: calc(100% - 124rem - 2rem);
        top: -.1rem;
        @include tablet{
            #{$end-direction}: 1.1rem;
            top: .6rem;
        }
    }
    &--mobile-menu{
        float: #{$end-direction};
        color: inherit;
        text-decoration: none;
        font-weight: $bold-font;
        font-size: 1.4rem*$font_incra;;    
        line-height: 1.4rem*$font_incra;;  
    }
    &--mobile-menu-close{
        width: 2rem;
        height: 2rem;
        svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &--mobile-menu-social{
        width: 4.7rem;
        color: $white;
        display: inline-block;
        text-decoration: none;
        font-weight: $normal-font;
        font-size: 2rem*$font_incra;;    
        line-height: 1.9rem*$font_incra;;
    }
    &--brand-back{
        display: block;
        font-size: 1.6rem*$font_incra;;    
        text-align: center;
        line-height: 1.8rem*$font_incra;;
        margin: 2.3rem 0 3.2rem;
        font-weight: $bold-font;
    }
    
    &--address{
        color: inherit;
        text-decoration: none;
    }
    &--close-options{
        position: absolute;
        top: 3.3rem;
        #{$end-direction}: 7.6rem;
        height: 2.4rem;
        width: 2.4rem;
        svg{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @include tablet{
            top: 3rem;
            width: 1.6rem;
            height: 1.9rem;
            #{$end-direction}: 2.2rem;


        }
    }
	&--buying-options{
        height: 100%;
        width: 18rem;
        background-color: $pink;
        color: $black;
        text-decoration: none;
        font-size: 1.6rem*$font_incra;;
        @include tablet{
            font-size: 1.2rem*$font_incra;;
            width: 12.6rem;
        }
    }
    &--font-page-nav{
        font-size: 1.6rem*$font_incra;;
        margin: 0 2rem;
        color: $black;
        transition: all .02s ease-in;
        &.active{
            color: $pink;
        }
    }
    &--product-page{
        font-weight: $bold-font;
        @include tablet{
            font-weight: $normal-font;
        }
    }
    &--cart-back-to-shop{
        width: 18.4rem;
        font-size: 1.2rem*$font_incra;;
        line-height: 2.5rem*$font_incra;;
        height: 3.5rem;
        align-items: center;
        display: flex;
        text-decoration: none;
        border-radius: 2.25rem;
        color: $white;
        background-color: $pink;
        font-weight: normal;
        padding: 0 1.98rem;
        box-sizing: border-box;
        justify-content: space-between;
        svg{
            width: 2rem;
            height: 1.2rem;
            object-fit: contain;
        }
        @include tablet{
            width: 11rem;
            font-size: 1rem*$font_incra;;
            height: 2.6rem;
            padding: 0;
            justify-content: center;
            svg{
                display: none;
            }
        }
    }
    &--terms-nav{
        display: inline-block;
        color: #2A3D38;
        margin-bottom: 1.9rem;
        font-size: 1.6rem*$font_incra;;    
        white-space: nowrap;
        &.active{
            color: $pink;
        }
    }
    &--license-label{
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        color: inherit;
        text-decoration: none;
        letter-spacing: inherit;
    }
    &--add-sucess{
        color: $white;
        width: 24.4rem;
        display: flex;
        height: 6.421rem;
        align-items: center;
        justify-content: center;
        border-radius: 10rem;
        font-size: 1.8rem*$font_incra;;
        text-decoration: none;
        font-weight: $normal-font;
        @include tablet{
            width: 21.7rem;
            height: 4.258rem;
            font-size: 1.6rem*$font_incra;;
            border-radius: 2.6rem;
        }
    }
    &--font-cart{
        background-color: $pink;
        @include tablet{
            // background-color: #2A3D38;
            font-size: 1.4rem*$font_incra;;
        }
    }
    &--back-to-fonts{
        background-color: #2A3D38;
        @include tablet{
            background-color: transparent;
            color: #2A3D38;
            font-size: 1.4rem*$font_incra;;
            text-decoration: underline;
            margin-top: 1.86rem;
            white-space: nowrap;
        }
    }
    &--404-home{
        color: #EE7FAB; 
        font-size: 30rem*$font_incra;;   
        font-weight: $bold-font;   
        line-height: 29.4rem*$font_incra;; 
        text-align: center;
        display: block;
        margin: -10rem auto 4.9rem;
        @include tablet{
            font-size: 7rem*$font_incra;;
            line-height: 1;
            margin: -3rem auto 4rem;
        }

    }
    &--invisible{
        text-decoration: none;
        color: inherit;
    }
    &--back-to-fonts-thanks{
        display: block;
        margin:2.5rem auto;
        line-height: 4.5rem*$font_incra;;
        width: 17.1rem;
        font-size: 1.6rem*$font_incra;;
        text-align: center;
        color: $white;
        background-color: $pink;
        border-radius: 2.25rem;
        text-decoration: none;

    }
}