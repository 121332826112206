.brand{
	&--single{
		&__section{
			background-color: $white;
		}
		&__main-content{
			padding-top: 0;
		}
		&__content-wrap{
			max-width: 124rem;
			@include tablet{
				max-width: 100%;
				padding: 0 2.2rem;
			}
		}
		&__section{
			&--main{
				height: 100vh;
				display: flex;
				justify-content:center;
				align-items: center;
				@include tablet{
					height: 50.06rem;
				}
				img{
					max-width: 100%;
					height: auto;
				}

			}
		}
		.video{
			width: 100vw;
			position: relative;
			margin-#{$start-direction}: calc(-50vw + 124rem/2);
			height: calc(100vw/16*9);
			@include tablet{
				margin-#{$start-direction}: -2.2rem;
			}
			.single-video{
				width: 100%;
				height: 100%;
			}
			
		}
		.two_column_text_grid{
			@include make-row();
			// padding: 13.3rem 0 3.9rem;
			justify-content: space-between;
			@include tablet{
				padding: 3.3rem 0;
			}
			
			&__segment{
				&--first{
					@include make-col(5.5);
					@include tablet{
						@include make-col(12);
						@include make-row();
					}
				}
				&--second{
					@include make-col(6.5);
					@include tablet{
						display: none;
					}
				}
			}
			&__title{
				margin: 0;
				font-size: 5rem*$font_incra;;	
				font-weight: $bold-font;	
				line-height: 1;
				@include tablet{
					@include make-col(12);
					font-size: 2.2rem*$font_incra;;	
				}
			}
			&__subtitle{
				font-size: 4rem*$font_incra;;
				line-height: 1;
				margin: 0 0 2.9rem;
				// white-space: nowrap;
				font-weight: $normal-font;
				@include tablet{
					@include make-col(12);
					font-size: 2.2rem*$font_incra;;	
					margin: 0 0 2.5rem;
				}
			}
			&__text{
				margin: 0;
				color: $gray;
				font-size: 1.8rem*$font_incra;;	
				line-height: 1.3;
				white-space: pre-line;
				@include tablet{
					@include make-col(12);
					font-size: 1.4rem*$font_incra;;	
					// line-height: 2.1rem*$font_incra;
					margin: 0 0 4.5rem;
				}
			}
			.project-description{
				font-size: 1.5rem*$font_incra;;	
				line-height: 2.1rem*$font_incra;;
				margin-bottom: 2.1rem;
				display: flex;
				&__titles{
					color: #2A3D38;
					margin-#{$end-direction}: 3rem;
				}
				&__texts{
					color: #6A7975;
					a{
						color: inherit;
						text-decoration: underline;
					}
				}
				@include tablet{
					@include make-col(12);
					font-size: 1.2rem*$font_incra;;	
					line-height: 1.9rem*$font_incra;;
					margin-bottom: 0;
				}
				&__title{
					margin: 0;
					font-weight: $normal-font;
				}
				&__text{
					margin: 0;
					color: $gray;
					white-space: pre-line;
				}
			}
		}
		.single_image{
			// margin: 4rem 0;
			@include tablet{
				// margin: 1.5rem 0;
			}
			&__img{
				width: 100%;
				height: auto;
			}
		}
		.testimonial{
			padding: 3.8rem 38rem;
			margin: 4rem 0 9rem;
			width: 100vw;
			margin-#{$start-direction}: calc(-50vw + 124rem/2);
			text-align: center;
			background-color: #D8D8D8;
			box-sizing: border-box;
			@include tablet{
				margin-#{$start-direction}: -2.2rem;
				padding: 3.8rem 3rem;
			}
			&__text{
				font-weight: $bold-font;
				font-size: 2rem*$font_incra;;
				margin: 3.4rem 0;
				line-height: 1.3;

			}
			&__author{
				color: #353535;
				font-size: 1.8rem*$font_incra;;

			}
			

		}
		.full_size_image{
			// margin: 4rem 0;
			width: 100vw;
			margin-#{$start-direction}: calc(-50vw + 124rem/2);
			@include tablet{
				margin: 1.5rem 0;
				margin-#{$start-direction}: -2.2rem;
			}
			&__img{
				width: 100%;
				height: auto;
				
			}
		}
		.dual_images{
			@include make-row();
			// margin:4rem 0 6rem;
			justify-content: space-between;
			@include tablet{
				display: block;
				margin: 0;
			}
			&__img{
				@include make-col(5.76);
				height: auto;
				object-fit: cover;
				@include tablet{
					// @include make-col(12);
					flex: unset;
					max-width: 100%;
					height: auto;
					display: block;
					margin-bottom: 1.5rem;
					width: 100%;
					object-fit: unset;

				}

			}
		}
		.font_display{
			width: 100vw;
			position: relative;
			#{$start-direction}: calc(-50vw + 50%);
			padding: 5.7rem 10rem 8.1rem;
			box-sizing: border-box;
			transition: all .2s ease-in;
			@include tablet{
				padding: 1.7rem 2.2rem 4rem; //TOHMCHECK padding-bottom
			}
			&__text{
    			word-break: break-all;
				line-height: 1.02;
				transition: color .2s ease-in;
			}
			&__controls{
				display: flex;
				margin-bottom: 4.2rem;
    			justify-content: space-between;
    			@include tablet{
    				align-items: center;
    				margin-bottom: 3.5rem;
    			}
			}
		}
		.two_column_text_image{
			@include make-row();
			align-items: center;

			justify-content: space-between;
			&__segment{
				width: 60rem;
				@include tablet{
					@include make-col(12);
				}
				img{
					width: 100%;
					height: auto;
				}
				&--first{
					width: 50.6rem;
					@include tablet{
						@include make-col(12);
						margin-bottom: 1.5rem;
					}

				}
			}
			&__title{
				font-weight: 600;
				// padding-#{$start-direction}: 3.3rem;
				font-size: 2.5rem*$font_incra;;
				line-height: 3.1rem*$font_incra;
				@include tablet{
					margin: 0 0 .5rem;
					font-size: 1.6rem*$font_incra;	
					line-height: 3.1rem*$font_incra;
				}
			}
			&__text{
				color: #6A7975;
				font-size: 1.8rem*$font_incra;;
				line-height: 1.3;
				margin-bottom: 2.5rem;
				@include tablet{
					font-size: 1.2rem*$font_incra;	
				}
			}

		}
		.centered_title_and_text{
			max-width: 68rem;
			text-align: center;
			// margin: 3.9rem auto 2.5rem;
			margin: 0 auto;
			@include tablet{
				max-width: 100%;
				// margin: 2.1rem auto 3rem;
			}
			&__title{
				margin: 0 0 2.5rem;
				font-weight: $bold-font;
				font-size: 2.5rem*$font_incra;;		
				line-height: 3.1rem*$font_incra;	
				@include tablet{
					margin: 0 0 .5rem;
					font-size: 1.6rem*$font_incra;;	
					line-height: 3.1rem*$font_incra;	
				}
			}
			&__text{
				margin: 0;
				color: $gray;
				font-size: 2rem*$font_incra;;	
				line-height: 1.3;	
				@include tablet{
					font-size: 1.2rem*$font_incra;	
				}

			}

		}
		.navigation{
			@include make-row();
			margin-top: 7.3rem;
			margin-bottom: 8.75rem;
	    	justify-content: space-between;
	    	&__btn-wrap{
	    		@include make-col(4);
	    		&:first-child{
	    			text-align: $start-direction;
	    		}
	    		&:nth-child(2){
	    			text-align: center;
	    		}
	    		&:nth-child(3){
	    			text-align: $end-direction;;
	    		}
	    	}
		}
	}
	&--index{
		margin-bottom: 4rem;
		width: 60rem;
		// @include make-col(5.76);
		text-decoration: none;
		@include tablet{
			margin-bottom: 3.2rem;
			@include make-col(12);
		}
		&:hover{
			.brand--index__mask{
				// max-height: 100%;
				opacity: 1;
			}
		}
		&__mask{
			top: 0;
			#{$start-direction}: 0;
			position: absolute;
			width: 100%;
			height: 100%;
			display: flex;
			opacity: 0;
			color: $white;
			flex-direction: column;
			align-items: flex-start;
			box-sizing: border-box;
			justify-content: flex-end;
			transition: all .3s ease-in;
			padding-#{$start-direction}: 3.3rem;
			padding-#{$end-direction}: 8.9rem;
			padding-bottom: 2.8rem;
			background-size: cover;
			background-position: center;
			background-blend-mode: multiply;

		}
		&__image-wrap{
			position: relative;
			height: 60rem;	
			width: 100%;
			background-size: cover;
			background-position: center;
			background-blend-mode: multiply;

			@include tablet{
				height: 33rem;
				margin-bottom: .6rem;
			}
			
		}
		&__title{
			margin: 0;
			color: $white;
			font-size: 2rem*$font_incra;	
			font-weight: 300;
			line-height: normal;
			margin-bottom: 1rem;
			font-weight: $bold-font;
			@include tablet{
				font-size: 1.4rem*$font_incra;	
				line-height: 2.2rem*$font_incra;
			}
		}
		&__subtitle{
			margin: 0;
			font-size: 2.5rem*$font_incra;
			font-weight: 300;
			line-height: 2.7rem*$font_incra;
		}
	}
	
}