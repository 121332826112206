.info{
	&__content-wrap{
		max-width: 116rem;
		@include tablet{
			max-width: 100%;
			padding: 0 2.2rem;
		}
	}
	&__title{
		margin-bottom: 6.2rem;
		@include tablet{
			margin-bottom: 2.7rem;
		}
	}
	&__container{
		display: flex;
		justify-content: space-between;
		@include tablet{
			flex-direction: column-reverse;
		}
	}
	&__text-wrap{
		width: 60rem;
		@include tablet{
			width: 100%;
		}
	}
	&__text{
		margin: 0;
		color: #6A7975;
		font-size: 1.6rem*$font_incra;;
		line-height: 1.3;
		white-space: pre-line;

		@include tablet{
			font-size: 1.2rem*$font_incra;;
			list-style: 2.1rem;
			margin-bottom: 4rem;
		}
	}
	.key-value{
		width: 46.6rem;
		color: #2A3D38;
		@include tablet{
			width: 100%;
		}
		&__set{
			@include make-row();
			font-size: 1.5rem*$font_incra;;
			line-height: 1.3;
			margin-bottom: 0rem;
			margin-bottom: 1rem;


			@include tablet{
				font-size: 1.2rem*$font_incra;;
				margin-bottom: .5rem;
				
			}
		}
		&__title{
			@include make-col(5.5);
			font-weight: bold;
			margin: 0;
			white-space: nowrap;
		}
		&__text{
			@include make-col(6.5);
			box-sizing: border-box;
			// line-height: 3.7rem;
			padding-#{$start-direction}: 1.4rem;
			p{
				margin: 0;
			}
			a{
				color: $pink;
				text-decoration: underline;
			}
		}
	}
}