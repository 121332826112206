.footer{
	color: $white;
	position: relative;
	background-color: $green-gray;
	&--small{
		height: 9.15rem;
		font-size: 1.5rem*$font_incra;
		line-height: 1.4;
		@include tablet{
			height: 4.5rem;
			font-size: 1.2rem*$font_incra;
			line-height: 1;
			font-weight: $normal-font;
		}
		.footer__content-wrap{
			align-items: center;
			max-width: 100%;
			margin: 0 9.9rem;
			@include tablet{
				max-width: 100%;
				margin: 0 auto;
    			justify-content: center;
			}
		}
		.footer__segment{
			@include make-col(6);
			@include tablet{
				@include make-col(12);
			}
			&:last-child{
				text-align: $end-direction;
				@include tablet{
					text-align: center;
				}
			}
		}
	}
	&--big{
		height: 37.3rem;
		font-size: 1.5rem*$font_incra;
		line-height: 1.4;
		padding: 5.8rem 0 4rem;
		box-sizing: border-box;
		@include tablet{
			height: auto;
			font-size: 1.2rem*$font_incra;
			// line-height: 1.8rem*$font_incra;
			padding: 0rem 2.3rem 3.7rem; 
		}
    	
    	
		.footer__copy{
			margin-#{$start-direction}: 30.2rem;
			// margin-#{$end-direction}: 29.5rem;
		}
		.footer__segment{
			display: flex;
			flex-direction: column;
		}
	}
	// &::after{
	// 	content: '';
	// 	top: 0;
	// 	left: 0;
	// 	width: 100%;
	// 	height: 100%;
	// 	position: absolute;
	// 	background-color: rgba(0,0,0,0.66);
	// }
	&__content-wrap{
		@include make-row();
		height: 100%;
		z-index: 1;
		margin: 0 auto;
		position: relative;
		align-items: flex-start;
		max-width: 134rem;
		justify-content: flex-start;
	}
	&__copy{
		margin-#{$start-direction}: 5.5rem;
		// margin-#{$end-direction}: 8.5rem;
	}
	&__segment{
		@include make-col(24,134);
		&--cta{
			color: $pink;
			// margin-#{$end-direction}: 16rem;
			margin-bottom: 5.7rem;
			@include tablet{
				@include make-col(12);
				margin-bottom: 2rem;
				margin-#{$end-direction}: 0rem;
			}

		}
		&--address{
			margin-#{$start-direction}: 12rem;
			@include tablet{
				@include make-col(12);
				margin-#{$start-direction}: 0rem;
			}

		}
		&--social{
			
			align-items: flex-start;
			margin-#{$start-direction}: 4.1rem;
			@include tablet{
				@include make-col(12);
				margin-top: 3.9rem;
				@include make-col-offset(0);
				margin-#{$end-direction}: 0rem;
				justify-content: flex-end;
				flex-direction: row-reverse !important;

			}

		}
		&--credits{
			@include make-col(36.1,134);
			// align-items: flex-end;
			@include tablet{
				display: none !important;
			}

		}
		&--legal{
			padding-#{$start-direction}: 36rem;
			@include make-col(12);
			flex-direction: row !important;
		    justify-content: flex-start;
			color: $gray;
			font-size: 1.5rem*$font_incra;
			line-height: 3.4rem*$font_incra;
			flex-direction: row;
			@include tablet{
				display: none !important;
			}
		}
	}
	&__cta-text{
		white-space: pre-line;
		@include tablet{
			width: 27.2rem;
		}
	}
	&__address{
    	font-style: normal;
    	// a{
    	// 	white-space: pre-line;
    	// 	@include tablet{
    	// 		white-space: unset;
    	// 	}
    	// }
    	@include tablet{
			margin-top: 0;
			white-space: normal;
			width: 13rem;
			width: 100%;
			margin-bottom: 2.3rem;
    	}
	}
	&__contact-details{
		font-style: normal;
	}
	&__text{
		&--social-title{
			display: block;
			margin-bottom: 3rem; //TOHMCHECK
			@include tablet{
				display: none;
			}
		}
	}
	&__code-credit{
		display: block;
    	// margin-top: 5.5rem; //TOHMCHECK
	}
	&__form{
		margin-top:2.8rem;
		@include tablet{
			box-sizing: border-box;
			margin: 0 0 2.6rem;
			background-color: $pink;
			width: 100vw;
			margin-#{$start-direction}: -2.3rem;
			padding: 3.1rem 2.2rem 3.3rem;

		}
	}


}