.header{
	position: fixed;
    width: 100%;
    left: 0;
    height: 6.7rem;
    z-index: 10 ;
    mix-blend-mode: difference;
    color: $white;
    transition: all .2s ease-in;
    
    &.normal-blend{
        mix-blend-mode: unset;
    }
    &__content-wrap{
    	display: flex;
    	margin: 0 7.8rem;
    	max-width: 100%;
    	padding-top: 2.6rem;
    	align-items: center;
    	justify-content: space-between;
        @include tablet{
            max-width: 100%;
            margin: 0 auto;
            padding-right: 2.2rem;
            padding-left: 2.2rem;
        }
    }
 
}
.mobile-menu-wrap{
    display: none;
    position: relative;
    @include tablet{
        display: block;
    }

}
.mobile-menu{
    top:0;
    left: 0;
    width: 100%;
    position: fixed;
    overflow: hidden;
    color: $white;
    z-index: 9999;
    background-color: $dark-gray;
    &__content-wrap{
        width: 100%;
        box-sizing: border-box;
        padding-#{$start-direction}: 2.2rem;
        padding-#{$end-direction}: 3.5rem;
    }
    &__header{
        padding-top: 3.2rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__segment{
        &--social-links{
            margin-bottom: 6.3rem;
        }
        &--credits{
            @include make-row();
            font-size: 1.2rem*$font_incra;;    
            line-height: 1.8rem*$font_incra;;
            margin-bottom: 1.6rem;
            justify-content: space-between;
        }
        &--legal{
            color: $gray;
            font-size: 1.2rem*$font_incra;;   
            line-height: 3.4rem*$font_incra;;
        }
    }
    &__code-credit{
        @include make-col(5.3);
    }
    &__font-credit{
        @include make-col(5);
    }
    &__items-wrap{
        padding: 0;
        list-style: none;
        margin: 3.5rem 0 6.2rem;

    }
    &__list-item{
        color: $white;
        font-size: 2rem*$font_incra;;    
        line-height: 5rem;
        &--lang{
            display: inline-block;
        }
        a{
            color: inherit;
            text-decoration: none;
        }
        &--current_lang{
            color: $pink;
        }
        &--en{

            &::after{
                content:  '/';
                margin: 0 .25rem;
                color: $white;
            }
        }
        &--cart{
            @include tablet{
                position: relative;
                .icon--cart-count{
                    #{$start-direction}: 5rem;
                    span{
                        line-height: normal;
                    }
                }
            }
        }
    }
    &__copy{
        display: block;
    }

}
.header-nav{
	padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    @include tablet{
        display: none;
    }
    &__list-item{
    	font-size: 1.5rem*$font_incra;;
    	line-height: 1.5rem*$font_incra;;
    	width: 7.8rem;
    	text-align: center;
        // &:hover{
        //     a{
        //         color: $pink;
        //         text-decoration: underline;
        //     }
        // }
        a{
            color: inherit;
            text-decoration: none;
            transition: all .2s ease-in;

        }
        &--cart{
            position: relative;
        }
    	&--lang{
    		width: auto;
            // display: none;
            
                
            
    	}
        &--current_lang{
            color: $pink;
        }
        &--en{
            margin-#{$start-direction}: 6.8rem;
            margin-#{$end-direction}: 0;
            &::after{
                content:  '/';
                margin: 0 .25rem;
                color: $white;
            }
        }
    }
   
}