.cart{
	&__footer{
		display: none;
	}
	&--empty{
		&__main-content{
			box-sizing: border-box;
			padding-top: calc(var(--header-height) + 6.7rem);
			min-height: calc(100vh - var(--footer-height));
		}
		&__content-wrap{
			max-width: 92rem;
			@include tablet{
				max-width: 100%;
				padding: 0 2.4rem;
			}
		}
		&__text-wrap{
			margin: 5.5rem 0;
			color: #2A3D38;	
			font-size: 6rem*$font_incra;	
			font-weight: bold;	
			line-height: 7.2rem*$font_incra;
			@include tablet{
				font-size: 5rem*$font_incra;
				line-height: 1.2;
			}
			p{
				margin-top: 0;
				margin-bottom: 3.8rem;
			}
		}
		&__btn{
			height: 4.5rem;
			font-size: 1.6rem*$font_incra;	
			width: 17.1rem;
			justify-content: center;


		}
	}
	.woocommerce-info{
		display: none !important;
	}
	.woocommerce{
		max-width: 99.2rem;
		margin: 0 auto;
		padding-bottom: 5.5rem;
		@include tablet{
			max-width: 100%;
			padding: 0 2.4rem;
			.woocommerce{
				padding: 0;
			}
		}
	}
	&__title-wrap{
		display: flex;
		justify-content:space-between;
		align-items: center;
		padding-bottom: 1.1rem;
		border-bottom: 1px solid #2A3D38;
		@include tablet{
			padding-bottom: .6rem;
		}
	}
	&__title{
		margin: 0;
		font-size: 2rem*$font_incra;
		font-weight: bold;
		line-height: 2.5rem*$font_incra;
		@include tablet{
			font-size: 1.6rem*$font_incra;
		}
	}
	.cart-collaterals{
		margin-bottom: 5.2rem;
		@include tablet{
			margin-bottom: 3.5rem;
		}
		.cart_totals {
			color: #2A3D38;	
			display: flex;
			justify-content: space-between;
			font-size: 2rem*$font_incra;	
			line-height: 5.3rem*$font_incra;
			padding: 0 1rem;
			@include tablet{
				padding: 0 2.3rem;
				font-size: 1.2rem*$font_incra;
				line-height: 1.2rem*$font_incra;
				margin-bottom: .8rem;
				font-weight: 600;
			}
			&--total{
				color: $white;
				background-color: #140AE7;
				@include tablet{
					font-size: 1.6rem*$font_incra;
					line-height: 4.2rem*$font_incra;
					margin-bottom: 0;
				}
			}
		}
	}
	&__coupon-wrap{
		display: flex;
		align-items: center;
		@include tablet{
			margin-top: 1.4rem;
			justify-content: space-between;
		}
		.woocommerce-form-coupon-toggle{
			color: #2A3D38;		
			font-size: 1.2rem*$font_incra;	
			line-height: 2.5rem*$font_incra;
			margin-#{$end-direction}: 1rem;
			@include tablet{
				margin-#{$end-direction}: 0rem;
			}
		}
		.woocommerce-error{
			display: none;
		}
		form{
			display: flex !important;
			p{
				margin: 0;
			}
			p:not(.form-row){
				display: none;
			}
			.form-row-last{
				width: 0px;
			}
			input{
				-webkit-appearance: none;
				background-color: transparent;
				font-family: inherit;
				font-size: 1.2rem*$font_incra;
				line-height: 2.5rem*$font_incra;
				padding-#{$start-direction}: 1rem;
				width: 12.9rem;	
				border: 1px solid #6A7975;	
				border-radius: .2rem;
				@include tablet{
					font-size: 1rem*$font_incra;
					border-radius: .4rem;
				}
			}
			button[type="submit"]{
				visibility: hidden;
				padding: 0;
				width: 0px;
				height: 0px;
			}
		}
	}
	.checkbox{
		padding-#{$start-direction}: 0px;
	}
	input[type="checkbox"] , input[type="radio"]{
		opacity: 0;
		#{$start-direction}: 0;
		position: absolute;
		width: 3rem;
		height: 100%;
		cursor: pointer;
		&:checked~label , &:checked~span{
			&::before{
				background-color: #2A3D38;
			}
		}
		&~label , &~span{
			color: #6A7975;	
			font-family: inherit;	
			font-size: 1.6rem*$font_incra;	
			line-height: 2.5rem*$font_incra;
			display: flex;
			align-items: center;
			@include tablet{
				font-size: 1.2rem*$font_incra;
			}

			&::before{
				content: '';
				display: inline-block;
				width: 2.6rem;
				height: 2.6rem;
				border: 1px solid #2A3D38;
				border-radius: .3rem;
				margin-#{$end-direction}: .85rem;
				transition: all .2s ease-in;
				@include tablet{
					width: 1.4rem;
					height: 1.4rem;
					margin-#{$end-direction}: .4rem;
				}
			}
		}
	}
}

.woocommerce{
	.order_details{
		margin: 21.5rem 0 3.9rem;
		list-style: none;
		display: flex;
		justify-content: space-between;
		font-size: 1.8rem;
		padding: 3.8rem 0;
		border-top: 1px solid;
		border-bottom: 1px solid;
		font-weight: bold;
		@include tablet{
			flex-wrap: wrap;
			font-size: 1.4rem;
			margin: 3.9rem 0 ;

		}
		li{
			@include tablet{
				width: 100%;
				margin-bottom: 1rem;
			}
			strong{
				font-weight: normal;
			}
			&.total{
				order: 4;
				color: $pink;
				@include tablet{
					margin-bottom: 0;;
				}
			}
		}
	}
}