html {
	overflow-x: hidden; 
}
body{
	margin: 0;
	overflow-x: hidden; 
	background-color: $white;
	&.locked{
		position: fixed;
		width: 100vw;
    	// height: var(--window-height);

	}
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
:focus{
	outline: none;
}
.main{
	padding-top: var(--header-height);
	background-color: #ffffff;

	&__content-wrap{
		max-width: 144rem;
		margin: 0 auto;
	}
}
.slick-list{
	padding: 2rem 0 !important;
}

.split-text{
	&__line{
		width: 0px;
		overflow: hidden;
		white-space: nowrap;
	}
}
.text-switch{
	&__word{
		color: $pink;
		width: 0;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
	}
}

.fade-in-up{
	opacity: 0;
	transform: translateY(2rem);
	transition: all .7s ease-in;
	&--animated{
		opacity: 1;
		transform: translateY(0px);
	
	}
}

iframe{
	// position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	
}

button , input , textarea{
	font-family: inherit;
}
.dropdown{
	position: relative;
	&__btn{
		cursor: pointer;
		font-family: inherit;
		-webkit-appearance: none;
	}
	&__menu{
		width: 100%;
		position: absolute;
		height: 0px;
		overflow: hidden;
		background-color: $white;
		z-index: 10;
		
	}
	&__list-wrap{
		list-style: none;
		padding: 0;
		margin: 0;
	}
	&__list-item{
		a{
			display: block;
		}
	}
	&--font{
		&__list-item{
			border: 1px solid #D8D8D8;
			border-bottom: 0px;
			line-height: 4.05rem;
			a{
				display: block;
				padding: 0 5.15rem 0 3.45rem;
			}
			
			button, a{
				text-decoration: none;
				color: inherit;
				&:hover{
					color: $pink;
				}
			}
			&:last-child{
				border-bottom: 1px solid #D8D8D8;
			}
		}
		&__btn{
			font-size: inherit;
			line-height: inherit;
			letter-spacing: inherit;
			border: 0px;
			display: flex;
			align-items: center;
			background-color: transparent;
			&.open{
				svg{
					transform: rotate(180deg);
				}
			}
			svg{
				width: 2.1rem;
				height: 2.1rem;
				transition: all .2s ease-in;
				margin-#{$end-direction}: 1.15rem;
				@include tablet{
					margin-#{$end-direction}: .75rem;

				}
			}
		}
	}
	
}
.checkbox{
	position: relative;
	padding-#{$start-direction}: 4.6rem;
	.indicator{
		content: '';
		width: 2.1rem;
		height: 2.1rem;
		border-radius: .3rem;
		border: 1px solid #2A3D38;
		#{$start-direction}: 0.95rem;
		top: calc(50% - 1.05rem);
		position: absolute;
	}
	&.checked{
		.indicator{
			background-color: #2A3D38;
		}
	}
}
