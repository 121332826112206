// Bootstrap Imports
@import "../bootstrap/mixins/_breakpoints";
@import "../bootstrap/_variables";
@import "../bootstrap/mixins/_grid";



@mixin mobile(){//768
	@include media-breakpoint-down(sm){
		@content;
	}
}
@mixin tablet(){//992
	@include media-breakpoint-down(md){
		@content;
	}
}
@mixin landscape(){//1200
	@include media-breakpoint-down(lg){
		@content;
	}
}
@mixin desktop(){//1440
	@include media-breakpoint-down(xl){
		@content;
	}
} 

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
  &:focus{
		background-color: #eee;
		clip: auto !important;
		clip-path: none;
		color: #444;
		display: block;
		font-size: 1em;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

//Load lang
@import "lang/direction-rtl";
// Config Imports
@import "colors";
@import "typography";
@import "buttons";
@import "icons";
@import "tags";
@import "keyframes";
@import "links";
@import "form";
@import "carousel";