$font_incra: 1.16;


// Base Load
@import "config/base-he"; // Base layout settings, mixin, fonts and colors

// Load Partials
@import "partials/header";
@import "partials/footer";



// Load Components
@import "components/font-controls";
@import "components/loader";
@import "components/cart";


// Load Models
@import "models/brand";
@import "models/font";

//Load Pages

@import "pages/home-page";
@import "pages/brands";
@import "pages/fonts";
@import "pages/about";
@import "pages/cart";
@import "pages/terms";
@import "pages/thanks";
@import "pages/error-page";


//hebrew adjustment
.btn--cart-back-to-shop svg{
	transform: scale(-1, 1);
}

.brands__title-line:first-child{
	width: 100%;
}

#payment .error-msg{
	left: 3.5rem;
	@include tablet{
		right: -2.4rem;
		left: unset;
	}
}
#payment .wc_payment_methods .wc_payment_method .payment_box{
	@include tablet{
		right: -125%;
	}
}