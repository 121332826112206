.preload-wrap{
	top: 0;
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: white;
	z-index: 99999;
	// padding-top: 45vh;
	text-align: center;
	overflow: hidden;

	// &__letter{
	// 	color: $pink;
	// 	font-size: 8rem;
	// 	font-weight: $bold-font;
	// 	line-height: 8.5rem;
	// 	margin-bottom: 4.9rem;

	// }
	// &__text{
	// 	margin: 0;
	// 	font-size: 2rem;
	// 	letter-spacing: -0.005rem;
	// 	line-height: 3.3rem;
	// 	font-weight: $bold-font;
	// 	&--cite{
	// 		font-weight: $normal-font;
	// 	}
	// }
}