input:focus::-webkit-input-placeholder { color:transparent !important;; }
input:focus:-moz-placeholder { color:transparent !important;; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent !important;; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent !important; } /* IE 10+ */

.form{
	font-size: 2rem*$font_incra;
	font-weight: $bold-font;
	line-height: 2.5rem*$font_incra;;
	input{
		-webkit-appearance: none;

	}
	&--buying-options{
		@include tablet{
			height: calc(100% - 2.5rem - 2.7rem);
			
		}
		&__btn{
			display: block;
			color: $white;
			width: 17.1rem;
			border: 0px;
			text-decoration: none;
			line-height: 4.5rem*$font_incra;;
			border-radius: 2.25rem;
			background-color: $dark-gray;
			font-size: 1.6rem*$font_incra;;
			font-weight: normal;
			display: flex;
			cursor: pointer;
			justify-content: center;
			align-items: center;
			@include tablet{
				width: 100%;
				height: 5.2rem;
				border-radius: 0px;
				font-size: 2rem*$font_incra;;	
				line-height: 2.5rem*$font_incra;;
			}
			&.btn--font-cart{
				
				@include tablet{
					font-size: 1.6rem*$font_incra;;
					border-radius: 2.6rem;
					margin: 1.471rem auto 0;
				}
			}
			&.visited{
				color: #FF92BD;
				background-color: #FCB5D1;
			}
		}
	}
	&--newsletter{
		font-weight: normal;
		&__label{
			display: block;
			margin: 0rem;
			color: $white;
			font-weight: normal;
			font-size: 1.5rem*$font_incra;;	
			line-height: 2.8rem*$font_incra;;
			@include tablet{
				font-size: 1.2rem*$font_incra;;
				line-height: 2.7rem*$font_incra;;
				margin: 0 0 0.75rem;
			}
		}
		&__input-wrap{
			width: 100%;
			display: flex;
			@include tablet{
				width: 33.1rem;
				@include make-row();
				margin: 0 auto;
			}
		}
		&__error-msg{
			visibility: hidden;
			color: #FF4A4A;	
			font-size: 1.2rem*$font_incra;;	
			line-height: 2.8rem*$font_incra;;


		}
		&__input{
			padding: 0;
			margin: 0;
			width: 26.3rem;
			line-height: 5rem*$font_incra;;
			font-size: 1.5rem*$font_incra;;
			font-family: inherit;
			color: $pink;
			border-radius: 0px;
			background-color: transparent;
			border: 1px solid $pink;
			padding-#{$start-direction}: 1.4rem;
			transition: all .2s ease-in;
			box-sizing: border-box;
			@include tablet{
				color: $white;
				@include make-col(9);
				font-size: 1.2rem*$font_incra;;
				line-height: 2.7rem*$font_incra;;
				border: 1px solid #2A3D38;
				line-height: 4.4rem;
			}
			&.invalid{
				color: #FF4A4A;
				border: 1px solid #FF4A4A;
				

			}
			&--submit{
				width: 9.8rem;
				color: $white;
				cursor: pointer;
				font-weight: normal;
				background-color: $pink;
				padding-#{$start-direction}: 0rem;
				@include tablet{
					@include make-col(3);
					background-color: #2A3D38;
				}
				&:disabled{
					color: $pink;
					background-color: #FCB5D1;
				}
				&.invalid{
					color: $white;
					background-color: #FF4A4A;
					text-decoration: line-through;

				}
			}
		}
		&.valid{
			::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			  color: $pink;
			  opacity: 1; /* Firefox */
			  @include tablet{
			  	color: $white;
			  }
			}

			:-ms-input-placeholder { /* Internet Explorer 10-11 */
			  color: $pink;
			  @include tablet{
			  	color: $white;
			  }
			}

			::-ms-input-placeholder { /* Microsoft Edge */
			  color: $pink;
			  @include tablet{
			  	color: $white;
			  }
			}
		}
		&.invalid{
			::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
			  color: #FF4A4A;
			}

			:-ms-input-placeholder { /* Internet Explorer 10-11 */
			  color: #FF4A4A;
			}

			::-ms-input-placeholder { /* Microsoft Edge */
			  color: #FF4A4A;
			}
		}
	}
	&__top-segment{
		overflow: scroll;
		padding-bottom: 4rem;
		box-sizing: border-box;
		height: calc(100% - var(--bottom-segment-height));
		-webkit-overflow-scrolling: touch;
	}
	&__bottom-segment{
		border-top: 1px solid $white;
		padding-top: 2.3rem;
		@include tablet{
			border-top: 0;
			padding-top: 0rem;
			width: 100vw;
			position: absolute;
			bottom: 0;
			#{$start-direction}: 0;
		}
		.add-success{
			height: 0px;
			overflow: hidden;
			bottom: 0;
			left: 0;
			width: 100%;
			color: $white;
			background-color: rgba(252, 181, 209, 0.96);
			position: absolute;
			@include tablet{
				color: $pink;
			}
			&__content-wrap{
				padding: 18.7rem 43.3rem 20.08rem;
				@include tablet{
					padding: 1.3rem 7.9rem 3.7rem;
				}
				
			}
			&__text{
				display: block;
				text-align: center;
				font-size: 2.5rem*$font_incra;;
				margin-bottom: 6.6rem;
				@include tablet{
					margin-bottom: 3.2rem;
					font-size: 1.2rem*$font_incra;;
					font-weight: $bold-font;
					color: #FFFFFF;
					line-height: 3.3rem*$font_incra;;
				}
			}
			&__btn-wrap{
				display: flex;
				width: 100%;
				width: 57.3rem;
				margin: 0 auto;
				justify-content: space-between;
				@include tablet{
					width: 100%;
					flex-direction: column;
				}

			}
			&__btn{
				width: 4.8rem;
				height: 4.8rem;
				@include tablet{
					width: 1.5rem;
					height: 1.5rem;
				}
				svg{
					#Home-Mobile-Menu{
						stroke: $white;
						@include tablet{
							stroke: #EE7FAB;
						}
					}
				}
			}
		}
	}
	&__input-wrap{
		&--submit{
			visibility: hidden;
			display: flex;
			margin-top: 1.8rem;
			justify-content: flex-end;
			@include tablet{
				margin-top: 0px;
			}
		}
	}
	&__links-wrap{

		float: $start-direction;
		font-weight: normal;
		font-size: 1.5rem*$font_incra;;
		margin-top: 4.325rem;
		@include tablet{
			width: 100%;
			margin-top: 0;
			border-top: 1px solid;
			padding-top: 3.5rem;
			font-size: 1.2rem*$font_incra;;
		}
		p{
			margin: 0;
		}
		a{
			color: inherit;
		}
	}
	&__subtotal{
		text-align: $end-direction;
		font-size: 2rem*$font_incra;;
		font-weight: $bold-font;
		color: $dark-gray;
		visibility: hidden;
		line-height: 2.5rem*$font_incra;;
		@include tablet{
			// height: 5.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #140AE7;
			padding: 0 7rem;
    		justify-content: center;
			line-height: 5.2rem*$font_incra;;
		}
		
	}
	&__subtotal-text{
		font-weight: normal;
		margin-#{$end-direction}: .5rem;
		@include tablet{
			display: none;
		}
	}
	#full-price-total{
		font-weight: normal;
		color: #2A3D38;
		display: inline-block;
		// text-decoration: line-through;
		margin-#{$end-direction}: .5rem;
		position: relative;
		&::before{
			content: '';
			width: 100%;
			height: 1px;
			background-color: #2A3D38;
			top: calc(50% - .5px);
			position: absolute;
			right:0;
		}
		@include tablet{
			color: #6A93F7;
			margin-#{$end-direction}: 12rem;
		}
	}
	#price-total{
		color: #140AE7;
		@include tablet{
			height: 100%;
			color: $white;
		}
	}
	.dropdown{
		&__btn{
			width: 27.5rem;
			line-height: 4.1rem*$font_incra;;
			padding: 0 1.65rem;
			font-size: 1.6rem*$font_incra;;
			color: #FCB5D1;
			border-radius: 0.3rem;
			border: 1px solid #FCB5D1;
			background-color: transparent;
			transition: all .2s ease-in;
			display: flex;
			align-items: center;
			cursor: pointer;
			justify-content: space-between;
			span{
				white-space: nowrap;
			}
			@include tablet{
				width: 100%;
				font-size: 1.4rem*$font_incra;;
				white-space: nowrap;
				span{
					text-align: $start-direction;
					width: calc(100% - 1.4rem - 6rem);
				}

			}

			svg{
				margin-#{$start-direction}: 6rem;
				height: 1.4rem;
				width: 1.4rem;
				transition: all .2s ease-in;
				g{
					g{
						fill: $light-pink;
					}
				}
			}
			&.open{
				svg{
					transform: rotate(180deg);
					g {
						g{
							fill: $light-pink !important;
						}
					}
				}

			}
		}
		&__list-wrap{
			border: 1px solid $dark-gray;
			border-top: 0px;
		}
	}
	.checkbox-wrap{
		position: relative;
		padding-#{$start-direction}: 4.6rem;

		input:checked~label{
			color: $dark-gray;
			&::before{
				background-color: $dark-gray;
				border: 1px solid $dark-gray;
			}
		}
		input:checked~.license__prices{
			.license__price{
				opacity: 1;
			}

		}
		input:checked~.license__options{
			.dropdown__btn{
				color: $dark-gray;
				background-color: $white;
				border: 1px solid $dark-gray;
				svg{
					g{
						g{
							fill: $dark-gray;
						}
					}
				}

			}
		}
		input:checked~.dropdown{
			.dropdown__btn{
				color: $dark-gray;
				background-color: $white;
				border: 1px solid $dark-gray;
				svg{
					g{
						g{
							fill: $dark-gray;
						}
					}
				}

			}
		}
		input{
			cursor: pointer;
			position: absolute;
			width: 4rem;
			height: 100%;
			opacity: 0;
			z-index: 10;
			margin: 0;
			padding: 0;
			#{$start-direction}: 0;
			@include tablet{
				width: 3rem;
				top: 0;
				
			}
		}
		label{
			color: #FCB5D1;
			width: 12%;
			text-transform: capitalize;
			transition: all .2s ease-in;
			@include tablet{
				width: auto;
				font-size: 1.6rem*$font_incra;;	
				font-weight: $bold-font;	
				padding-#{$start-direction}: 3rem;
			}
			&::before{
				content: '';
				width: 2.1rem;
				height: 2.1rem;
				border-radius: .3rem;
				border: 1px solid #FCB5D1;
				#{$start-direction}: 0.95rem;
				top: calc(50% - 1.45rem);
				position: absolute;
				transition: all .2s ease-in;
				@include tablet{
					width: 1.5rem;
					height: 1.5rem;
					top: 3.05rem;
				}
			}
		}
		&--license{
			input{
				@include tablet{
					height: 7.3rem;
				}
			}
		}
		.license__price{
			opacity: 0;
			color: #140AE7;
			
			

		}
		&--buying-options{
			transition: all .2s ease-in;
			border-bottom: 1px solid $dark-gray;
			&:last-child{
				border-bottom: 0px;
			}
			&:hover{
				background-color: $light-pink;
			}
			input:checked~label{
				&::before{
					opacity: 1;
				}
			}
			input{
				width: 100%;
			}
			label{
				width: auto;
				color: $dark-gray;
				font-size: 1.6rem*$font_incra;
				font-weight: $normal-font;
				line-height: 4.8rem*$font_incra;
				@include tablet{
					font-size: 1.4rem*$font_incra;
					line-height: 4rem*$font_incra;
				}
				@include tablet{
					padding-#{$start-direction}: 0px;
				}
				&::before{
					opacity: 0;
					width: 1rem;
					height: 1rem;
					top: calc(50% - .5rem);
					border-radius: .1rem;
					#{$start-direction}: 1.4rem;
					@include tablet{
						top: calc(50% - .65rem);
					}
				}
			}
		}
	}

}
