.testers{

	&__content-wrap{
		max-width: 100%;
	}
	&__title{
		margin-bottom: 7.3rem; //CHECK WAS 10.3rem
		@include tablet{
			margin-bottom: 2.9rem;
		}
	}
	&__testing-text{
		margin-top: 4rem;
		padding: 0 14rem;
		@include tablet{
			padding: 0 2.3rem ;
		}
		p{
			padding: 5.7rem 0 9.5rem;
			width: 100%;
			margin: 0;
			overflow: hidden;
			white-space: pre-wrap;
			word-wrap: break-word;
			@include tablet{
				padding: 1.2rem 0rem .7rem;
			}
		}
		&--first{
			p{
				padding-top: 6.9rem;
				padding-bottom: 7.5rem;
			}
			@include tablet{
				p{
					padding-top: 1.4rem;
					padding-bottom: .8rem;
				}
			}
		}
		
		p{
			width: 100%;
			-webkit-appearance: none;
			border: 0px;
			text-align: inherit;
			color: inherit;
			background-color: transparent;
			resize: none;
			font-size: inherit;
			line-height: inherit;
			font-family: inherit;
			font-weight: inherit;
			font-variation-settings: inherit;

		}


	}
	&__container{
		&--second{
			margin-top: 9rem;
		}
	}
	.font-controls{
		max-width: 116rem;
		margin: 0 auto;
		position: relative;
		align-items: center;
		justify-content: space-between;
		@include tablet{
			align-items: unset;
			max-width: 32.5rem;
			
		}
		&>div{
			@include make-col(2,10);
		}
		.line-height-wrap{
			// width: 21.4rem;
			@include make-col(21.4,116);
    		margin-right: 3.5rem;
    		@include tablet{
				@include make-col(25, 325);
				margin-right: 0rem;
			}
    		&__container{
    			width: 100%;
    			justify-content: space-between;
    		}
		}
		.font-size-wrap{
			@include make-col(25, 116);
			@include tablet{
				@include make-col(25, 325)
			}
			.size-range{
				margin: 0 ;
				@include tablet{
					margin: 2.3rem auto;
				}
			}
			&__text{
				display: inline-block;
				color: #6A7975;
				font-size: 1.6rem*$font_incra;;
				line-height: 2.5rem*$font_incra;;
				margin-#{$end-direction}: 1.2rem;
				direction: $direction;
			}

		}
		
		.line-height-wrap__container, .font-size-wrap__container{
			display: flex;
			align-items: center;
			direction: ltr;
			@include tablet{
				display: block;
			}
			svg{
				width: 1.4rem;
				height: auto;
				@include tablet{
					width: 2.5rem;
					height: 1.7rem;
					object-fit: contain;
					&.svg-active{
						g{
							g{
								fill: $pink;
							}
						}
					}
				}
			}
		}
		.range-wrap{
			height: 0;
			overflow: hidden;
			position: absolute;
			top: 100%;
			width: 100vw;
			#{$start-direction}: 0;
			margin-#{$start-direction}: calc(-50vw + 16.25rem);
			background-color: #2A3D38;
		}
		.size-range{
			width: 16.2rem;
			// @include make-col(16.2,116);
			margin: 0 1.2rem;
			background-color: #2A3D38;
			@include tablet{
				width: 29.9rem;
				margin: 2.3rem auto;
				background-color: $pink;
			}
			&__indicator{
				&::after{
					background-color: #FFFFFF;
					border: .1rem solid #2A3D38;
					width: 2.3rem;
					height: 2.3rem;
				}
				@include tablet{
					&::after{
						background-color: #2A3D38;
						border: 1px solid $pink;
						top: -1.15rem;
						#{$start-direction}: -1.15rem;
					}

				}
			}

		}
		.align-select{
			// width: 12.5rem;
			direction: ltr;
			@include make-col(12.5,116);
			justify-content: space-between;
			display: flex;
			@include tablet{
				display: block;
				position: relative;
				@include make-col(25, 325)
			}
			button{
				@include tablet{
					position: absolute;
					width: 2.5rem;
					height: 1.7rem;
					padding: 0;
					display: none;
					&.active{
						display: block;
					}
					svg{
						width: 100%;
						height: 100%;
						object-fit: contain;
					}
				}
			}
		}
		.color-select{
			// width: calc(4.6rem + 2.1rem);
			@include make-col(6.7,116);
			display: flex;
			justify-content: space-between;
			@include tablet{
				@include make-col(50, 325);
				justify-content: flex-end;

			}
			.btn--color-select{
				margin: 0;
				@include tablet{
					width: 1.7rem;
					height: 1.7rem;
					position: relative;
					z-index: 0;
					&.active{
						z-index: 1;
					}
				}
				&:last-child{
					#{$start-direction}: -.9rem;
				}
			}

		}
		.dropdown{
			position: unset;
			@include make-col(27, 116);
			@include tablet{
				@include make-col(120, 325);
			}
			&__btn{
				font-size: 1.6rem*$font_incra;;
				color: $pink;
				@include tablet{
					font-size: 1rem*$font_incra;;
				}
				svg{
					margin-#{$end-direction}: 1.55rem;
					@include tablet{
						margin-#{$end-direction}: 0.85rem;
					}
				}
			}
			&__menu{
				width: 22.3rem;
				width: auto;
			}

			&--font{
				&__list-item{
					font-size: 1.2rem*$font_incra;;
					line-height: 3rem*$font_incra;;
					button{
						padding-top: 0;
						padding-#{$start-direction}: 3.5rem;
						padding-#{$end-direction}: 5rem;
						padding-bottom: 0;
					}
				}
			}
		}
	}
}