.woocommerce-cart-form{
margin-top: 6.9rem;
@include tablet(){
margin-top: 3.2rem;
padding: 0 1rem;
}
&__contents{
display: block;
thead{
display: none;
}
tbody{
display: block;
tr{
&.woocommerce-cart-form__cart-item {
	display: flex;
	height: 5.6rem;
	position: relative;
	padding-bottom: 0rem;
	border-bottom: 1px dashed #2A3D38;
	@include tablet{
		height: 4.7rem;
	}

}
td{
display: block;
}

.product-name{
	color: #2A3D38;
	text-decoration: none;
	font-size: 2rem*$font_incra;;
	line-height: 5.3rem*$font_incra;;
	text-align: #{$start-direction};
	padding-#{$start-direction}:4rem;
	@include tablet{
		font-size: 1.2rem*$font_incra;;
		line-height: 4.7rem*$font_incra;;
		padding-#{$start-direction}:2.1rem;

	}

	
		
	
	
	
.woocommerce-Price-amount{
display: none;
@include mobile(){
display: block;
}
}
}
.product-price{
display: none;
}
.product-quantity{
display: none;
}
.product-subtotal{
	position: absolute;
	#{$end-direction}:0;
    height: 5.6rem;
    display: flex;
    align-items: center;
    padding-#{$end-direction}: 1rem;
    @include tablet{
    	height: 4.7rem;
    }

	.woocommerce-Price-amount{
		font-size: 2rem*$font_incra;;
		font-weight: 300;
		line-height: normal;
		@include tablet{
			font-size: 1.2rem*$font_incra;;
		}
	}
}
.product-remove{
	align-items: center;
	position: absolute;
	top: 1.7rem;
	#{$start-direction}:0;
	@include tablet{
		top: 1.6rem;
	}
	a{
		font-size: 0px;
		display: block;
		background: url(images/close.svg) center / contain no-repeat;
		border: 0;
		height: 2rem;
		width: 2rem;
		@include tablet{
			height: 1.3rem;
			width: 1.3rem;
		}
	}
}
.actions{
width: 100%;
position: relative;
button[name="update_cart"]{
	visibility: hidden;
	height: 1.8rem;
}
.coupon{
width: 100%;
display: none;
text-align: right;
.input-text{
margin:0;
background-color: $white;
}
button{
background-color: $black;
color:$white;
}
}
}
}	
}
}

}

//checkout section
.woocommerce-billing-fields{
	&>h3{
		display: none;
	}
}
.woocommerce-additional-fields{
	&>h3{
		display: none;
	}
}
#order_review_heading{
	display: none;
}

.woocommerce-additional-fields__field-wrapper{
	@include make-row();
	margin-top: 1.4rem;
	.form-row{
		@include make-col(12);
		margin-bottom: 2.45rem;
		margin-top: 0;
		@include tablet{
			margin-bottom: 2.75rem;
		}
		label{
			box-sizing: border-box;

			display: flex;
			width: 100%;
			justify-content: space-between;
			color: #6A7975;	
			font-size: 1.6rem*$font_incra;;	
			font-weight: 300;
			line-height: 2.5rem*$font_incra;;
			text-transform: capitalize;
			padding-#{$start-direction}: .5rem;
			@include tablet{
				font-size: 1.2rem*$font_incra;;
				padding-#{$start-direction}: 1.05rem;
			}
			.optional{
				display: none;
			}
			
		}
		.woocommerce-input-wrapper{
			width: 100%;
			textarea{
				font-size: 1.6rem*$font_incra;;
				padding-#{$start-direction}: 1rem;
				-webkit-appearance: none;
				background-color: transparent;
				font-family: inherit;
				line-height: 1.3;
				width: 100%;
				border: 1px solid #2A3D38;
				border-radius: .3rem;
				box-sizing: border-box;
				min-height: 10rem;
				@include tablet{
					font-size: 1.2rem*$font_incra;;
					padding-#{$start-direction}: 1.05rem;
				}
			}
		}
	}

}
.woocommerce-billing-fields__field-wrapper{
	@include make-row();
	justify-content: space-between;
	padding-top: 3.8rem;
	@include tablet{
		padding-top: 1.4rem;
	}
	.form-row{
		@include make-col(5.75);
		margin-bottom: 2.45rem;
		margin-top: 0;
		@include tablet{
			@include make-col(12);
			margin-bottom: 0.95rem;
		}
		label{
			box-sizing: border-box;
			display: flex;
			width: 100%;
			justify-content: space-between;
			color: #6A7975;	
			font-size: 1.6rem*$font_incra;;	
			font-weight: 300;
			line-height: 2.5rem*$font_incra;;
			text-transform: capitalize;
			padding-#{$start-direction}: .5rem;
			@include tablet{
				font-size: 1.2rem*$font_incra;;
				padding-#{$start-direction}: 1.05rem;
			}
			.required{
				color: $pink;
				text-decoration: none;
			}
		}
		.woocommerce-input-wrapper{
			width: 100%;
			input{
				-webkit-appearance: none;
				background-color: transparent;
				font-family: inherit;
				line-height: 4.1rem*$font_incra;;
				width: 100%;
				border: 1px solid #2A3D38;
				border-radius: .3rem;
				box-sizing: border-box;
				font-size: 1.6rem*$font_incra;;
				padding-#{$start-direction}: 1rem;
				@include tablet{
					line-height: 3.1rem*$font_incra;;
					font-size: 1.2rem*$font_incra;;
				}
			}
		}
	}
	
	.woocommerce-invalid-required-field, .woocommerce-invalid-email{
		.woocommerce-input-wrapper{
			display: block;
			
		}

		label{
			color: $pink;
		}
		input{
			color: $pink;	
			border: 1px solid $pink !important;	
			font-weight: 600;
		}
		::-webkit-input-placeholder { /* Edge */
		  color: $pink;	
		}

		:-ms-input-placeholder { /* Internet Explorer 10-11 */
		  color: $pink;	
		}

		::placeholder {
		  color: $pink;	
		}
	}
	.optional{
		display: none;
	}
}

#payment{
	display: flex;
    flex-direction: row-reverse;
    position: relative;
    justify-content: flex-end;
    padding-#{$end-direction}: 20rem;
    margin-bottom: 1rem;
    @include tablet{
    	padding-#{$end-direction}: 0rem;
    	padding-bottom: 8.5rem;
    }
    .error-msg{
    	color: $pink;
		position: absolute;
		#{$end-direction}: -1rem;
		top: 5.5rem;
		font-size: 1.2rem*$font_incra;;
		font-weight: 600;
		@include tablet{
			#{$start-direction}: -2.4rem;
			top: 7.5rem;
			background-color: #FFFFFF;
			width: 100vw;
			height: 3.3rem;
			#{$end-direction}: unset;
			display: flex;
			align-items: center;
			justify-content: center;
		}
    }
    button[type="submit"]{
    	width: 17.7rem;
    	height: 4.5rem;
    	display: flex;
    	cursor: pointer;
    	justify-content: center;
    	align-items: center;
    	-webkit-appearance: none;
    	border: 0px;
    	color: $white;
    	font-family: inherit;
    	font-size: 1.6rem*$font_incra;;	
    	line-height: 2.5rem*$font_incra;;	
    	top: 0;
    	background-color: $pink;
    	position: absolute;
    	#{$end-direction}: 0;
    	border-radius: 2.25rem;
    	&:disabled{
    		background-color: #FCB5D1;
    	}
    	@include tablet{
    		height: 5.2rem;
    		top: calc(100% - 4.2rem);
    		border-radius: 0px;
    		width: 100vw;
    		#{$start-direction}: -2.4rem;
    	}

    }
    .required{
		display: none;
	}
	.wc_payment_methods{
		padding: 0;
		margin: 0;
		list-style: none;
		.wc_payment_method{
			margin-bottom: 1.6rem;
			position: relative;
			.payment_box{
				position: absolute;
				white-space: nowrap;
				color: #6A7975;
				font-size: 1.3rem*$font_incra;;
				@include tablet{
					right: -200%;
					position: absolute;
					font-size: 1rem*$font_incra;;
					line-height: 2.5rem*$font_incra;;
				}
			}
		}
		.payment_method_paypal{
			
			.payment_box{
				display: none !important;

			}
		}
	}
	.place-order{
		flex-grow: 2;
	}
	.newsletter-signup-wrap{
		position: relative;
	}
	.woocommerce-terms-and-conditions-wrapper{
		&>p{
			margin: 0;
			margin-bottom: 1.3rem;
		}
		label{
			position: relative;
			display: block;
			font-size: 0px;
		}
		
	}
	.woocommerce-terms-and-conditions-link{
		display: inline-block;
		margin-#{$start-direction}: 0rem;
		
    }	
}
.woocommerce-checkout-payment{
	.payment_method_paypal{
		label{
			img, a{
				display: none;
			}
		}
	}

}
.woocommerce-error{
	display: none;
}
.woocommerce-notices-wrapper{
	flex-grow: 2;
	font-size: 1.2rem*$font_incra;;
	line-height: 2.5rem*$font_incra;;
	margin-#{$start-direction}: 2rem;
	.rag-text{
		margin-#{$start-direction}: 0.5rem;
	}
}