
.fonts{
	&--shop{
		&__content-wrap{
			max-width: 124.3rem;
			padding-top: 14.6rem;
			@include tablet{
				max-width: 100%;
				padding: 0 2.2rem ;
				padding-top: calc(9.95rem - var(--header-height));
			}
		}
	}
	&__main-content{
		padding-top: 0;
		min-height: 100vh;
		background-color: $dark-gray;
	}
	&__section{
		&--coming-soon{
			
			// padding-bottom: 6.2rem;
			// @include tablet{
			// 	padding-bottom: 12.9rem;
			// }
		}
	}
	&__content-wrap{
		max-width: 124rem;
		text-align: center;
	}
	&__slider{
		padding-top: 18.35vh;
		@include tablet{
			padding-top: 14.9rem;
		}
	}
	&__title{
		color: $pink;	
		font-size: 3rem*$font_incra;	
		margin: 9.45vh 0 0;
		text-align: center;
		line-height: 4.5rem*$font_incra;	
		font-weight: $normal-font;	

		@include tablet{
			width: 80%;
			font-size: 2rem*$font_incra;	
			margin: 3.8rem auto 0;
			line-height: 2.8rem*$font_incra;
			// font-weight: $bold-font;	
		}
	}
	&__subtitle{
		color: $pink;	
		font-size: 3rem*$font_incra;	
		margin: -3rem 0 0;
		text-align: center;
		line-height: 9.5rem*$font_incra;	
		font-weight: $normal-font;	
		text-decoration: underline;
		@include tablet{
			font-size: 2rem*$font_incra;	
			margin: 1rem 0 0;
			line-height: 2.8rem*$font_incra;	
			
		}
	}
	.slick-slide {
		margin: 0 auto;
    	width: 100%;
    	height: 50.6vh;
    	img{
    		width: 100%;
    		height: 100%;
    		object-fit: contain;
    	}
    	@include tablet{
    		width: calc(100% - 1rem);
    		height: auto;
    		img{
    			height: auto;
    			object-fit: unset;
    		}
    	}
	}

}