.speciman{
	&__content-wrap{
		max-width: 116rem;
		padding: 8.7rem 0 13rem;
		@include tablet{
			max-width: 100%;
			padding: 3.7rem 2.3rem 4.7rem;
		}
	}
	&__container{
		&.hide-on-mobile{
			@include tablet{
				display: none;
			}
		}
		p{
			margin: 0;
		}
		&--single{
			text-align: center;
			@include tablet{
				text-align: $start-direction;
			}
		}
		&--double{
			display: flex;
			justify-content: space-between;
			&.column{
				@include tablet{
					display: block;
				}
			}
			.speciman__wrap{
				width: 56rem;
				@include tablet{
					width: 100%;
				}
			}
		}
		&--triple{
			display: flex;
			justify-content: space-between;
			&.column{
				@include tablet{
					display: block;
				}
			}
			.speciman__wrap{
				width: calc(100%/3.2);
				@include tablet{
					width: 100%;
				}
			}
		}
	}
	&__title{
		color: #6A7975;
		display: block;
		margin-bottom: 3.5rem;
		text-align: center;
		font-size: 1.6rem*$font_incra;;
		@include tablet{
			font-size: 1rem*$font_incra;;
			margin-bottom: .6rem;
		}
	}
}