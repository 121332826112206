.thank-you{
	// background-color: #2A3D38;
	&__main-content{
		text-align: center;
		background-color: transparent;
	}
	.footer{
		display: none;
	}
	.woocommerce-order-overview{
		display: none;
	}
	.woocommerce-order-details{
		display: none;
	}
	.woocommerce-customer-details{
		display: none;
	}
	.woocommerce{
		max-width: 72.7rem;
		margin: 0 auto;
		@include tablet{
			max-width: 27.9rem;
		}

	}
	&__title{
		margin: 18.3rem 0 0;
		color: $pink;	
		font-size: 8rem*$font_incra;	
		font-weight: bold;	
		line-height: 7.9rem*$font_incra;
		@include tablet{
			font-size: 5.5rem*$font_incra;
			line-height: 5.4rem*$font_incra;
			margin-top: 9.15rem;
		}
	}
	&__subtitle{
		font-size: 2.5rem*$font_incra;	
		font-weight: 300;	
		line-height: 2.4rem*$font_incra;	
		margin: 1.1rem auto 7.9rem;
		@include tablet{
			margin: .7rem 0 5rem;
			font-size: 1.6rem*$font_incra;	
		}
	}
	p{
		
		font-size: 1.8rem*$font_incra;	
		font-weight: 300;	
		line-height: 2.4rem*$font_incra;
    	margin: 0;

	}

}