.brands{
	background-color: $white;
	&__content-wrap{
		max-width: 124rem;
		@include tablet{
			max-width: 100%;
			padding: 0 2.2rem;
		}
	}
	&__title{
		margin: 16.2rem 0 9.1rem;
		color: $black;
		font-weight: $normal-font;
		font-size: 6rem*$font_incra;	
		line-height: .8;
		display: block;
		font-weight: $bold-font;

		@include tablet{
			font-size: 2.2rem*$font_incra;	
			margin: 3.9rem 0 1.5rem;
		}
	}
	&__title-line{
		display: inline-block;
		vertical-align: top;
		&:last-child{
			width: 100%;
		}
	}
	// &__text{
	// 	color: $gray;
	// 	font-size: 2rem;
	// 	margin: 0 0 6.3rem;
	// 	line-height: 3.3rem;
 //    	white-space: pre-line;
	// 	letter-spacing: -.005rem;
	// 	@include tablet{
	// 		margin: 0 0 2.9rem;
	// 		font-size: 1.4rem;	
	// 		letter-spacing: -.003rem;	
	// 		line-height: 2.1rem;
	// 	}
	// }
	&__brands-wrap{
		@include make-row();
		justify-content: space-between;
		margin-bottom: 7.9rem;
	}
	&__btn{
		margin-bottom: 16.2rem;
		display: none;
	}
}