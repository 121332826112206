.home-page{
	&__bkg{
		top:0;
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: rgba(0,0,0,0.4);
	}
	&__main-content{
		padding-top: 0px;
	}
	&__section{
		height: 100vh;
		position: relative;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	&__content-wrap{
		position: relative;
		max-width: 113.4rem;
		padding-top: 44.9rem;
		padding-top: 45.3vh;
		margin-#{$start-direction}: 9.95rem;
		margin-#{$end-direction} : 20.75rem;
		@include tablet{
			max-width: 100%;
			padding-top: 58.4vh;
			margin-#{$start-direction}: 2.2rem;
			margin-#{$end-direction} : 2rem;

		}
	}
	&__video-wrap{
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		#video{
			width: 100%;
			height: 100%;
		}
		iframe{
			width: 100vw;
			height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
			min-height: 100vh;
			min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	&__title{
		margin: 0;
		color: $white;
		font-size: 8rem*$font_incra;	
		line-height: .8;
		font-weight: $bold-font;	
		@include tablet{
			font-size: 3.3rem*$font_incra;	
		}
	}

}